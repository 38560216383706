.arco-message-error {
  box-shadow: none;
  border: none;
  padding: 10px 18px;
  .arco-message-icon {
    display: none;
  }
}

.arco-image-loader {
  background-color: rgba(0, 0, 0, 0);
}

.arco-image-loader-spin-text {
  margin-top: 20px;
}

.arco-list {
  border-radius: 0px;
}

.arco-calendar-panel .arco-calendar-cell-today::after {
  content: '';
  display: none !important;
}

.arco-calendar-panel
  .arco-calendar-cell:not(.arco-calendar-cell-selected):not(.arco-calendar-cell-range-start):not(
    .arco-calendar-cell-range-end
  ):not(.arco-calendar-cell-hover-range-start):not(.arco-calendar-cell-hover-range-end):not(
    .arco-calendar-cell-disabled
  ):not(.arco-calendar-cell-week)
  .arco-calendar-date-value:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
}

.arco-picker-container {
  background-color: var(--moonrise-dialog-bg);
  .arco-picker-date-value,
  .arco-picker-header-value {
    color: #fff;
  }
  .arco-picker-header-label:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  .arco-picker-header-icon {
    background-color: rgba(0, 0, 0, 0);
  }
  .arco-picker-cell-disabled {
    .arco-picker-date,
    .arco-picker-date-value {
      background-color: rgba(0, 0, 0, 0);
      color: #4a4a4a;
    }
  }
  .arco-picker-header-icon:not(.arco-picker-header-icon-hidden):hover {
    background-color: rgba(0, 0, 0, 0);
  }
}

.arco-affix {
  z-index: 19;
}