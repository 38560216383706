@import '../../../styles/mixin.scss';
@import '../../../styles/responsive.scss';

.nav-cart {
  --nav-cart-border-color: #d9d9d9;

  &_products {
    width: 100%;
    min-height: 80px;
    padding: 0px var(--nav-cart--products-padding);
    .products {
      border-top: 1px solid var(--nav-cart-border-color);
      .product {
        display: flex;
        height: var(--nav-cart--products-product-height);
        &_info {
          width: 100%;
          flex-grow: 1;
          border-bottom: 1px solid #424242;
          &--remove {
            width: var(--nav-cart--products-product-remove-w);
            height: var(--nav-cart--products-product-remove-h);
          }
        }
      }
    }

    .empty {
      border-top: 1.5px solid var(--nav-cart-border-color);
      height: var(--nav-cart--products-empty-h);
    }
  }

  &_calendar {
    &--body {
      .changer {
        &_icon {
          height: auto;
        }
      }
      .carousel {
        $disabledColor: #4a4a4a;
        width: calc(100% + var(--nav-cart--calendar-width-plus));
        margin-left: calc(var(--nav-cart--calendar-width-plus) / 2 * -1);
        &_head {
          height: var(--nav-cart--calendar-head-height);
          div {
            width: calc(100% / 7);
            color: $disabledColor;
          }
        }
        &_body {
          overflow: hidden;
          &--month {
            flex-shrink: 0;
            width: 100%;
            transition: all 0.4s ease-in-out;
            .day {
              position: relative;
              display: inline-block;
              width: calc(100% / 7);
              height: var(--nav-cart--calendar-row-height);
              line-height: var(--nav-cart--calendar-row-height);
              transition: all 0.2s;
              span {
                color: #fff;
                transition: all 0.2s;
                &:hover {
                  cursor: pointer;
                }
              }
              &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: var(--nav-cart--calendar-line-bottom);
                transform: translateX(-50%);
                width: var(--nav-cart--calendar-line-width);
                height: var(--nav-cart--calendar-line-height);
                display: block;
                border-radius: var(--nav-cart--calendar-line-height);
                transition: all 0.2s;
              }
            }
            .day-normal:hover {
              &::after {
                content: '';
                background-color: var(--moonrise-color-green);
              }
            }
            .day-today {
              &::after {
                content: '';
                background-color: #5b5b5b;
              }
            }
            .day-active {
              span {
                color: var(--moonrise-color-green);
              }
              &::after {
                content: '';
                background-color: var(--moonrise-color-green);
              }
            }
            .day-disabled {
              span {
                color: $disabledColor;
                &:hover {
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }
    }
  }

  &_address {
    &--title {
      border-bottom: 1px solid var(--nav-cart-border-color);
    }
    .input-group-line {
      height: var(--nav-cart--address-oneline);
      display: flex;
      align-items: flex-end;
    }
    .input-group {
      display: flex;
      border-bottom: 1px solid var(--moonrise-color-gray);
      height: var(--nav-cart--address-input-group);
      line-height: var(--nav-cart--address-input-group-lh);
      label {
        color: var(--moonrise-color-gray);
      }
      input {
        margin-left: var(--nav-cart--address-input-ml);
      }
    }
    .input-group-name {
      width: var(--nav-cart--address-name-w);
      flex-shrink: 0;
    }
    .input-group-tel {
      width: var(--nav-cart--address-tel-w);
      margin-left: var(--nav-cart--address-label-ml);
    }
    .input-group-detail {
      position: relative;
      .line {
        display: none;
        position: absolute;
        height: 1px;
        background-color: var(--moonrise-color-gray);
        width: 100%;
        left: 0;
      }
      .placeholder {
        position: absolute;
        top: 0;
        left: 0;
        color: #424242;
      }
      textarea {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        overflow: hidden;
        border: none;
        box-shadow: none;
        resize: none;
        background-color: rgba($color: #000000, $alpha: 0);
      }
    }
  }

  &_order {
    padding: 0px var(--nav-cart--products-padding);
    &--tip {
      div {
        color: #575757;
      }
    }
    &--code {
      width: var(--nav-cart--products-order-code);
      height: var(--nav-cart--products-order-code);
      // background-color: var(--nav-cart-border-color);
      position: relative;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--moonrise-bg);
      }
    }
    &--price {
      margin: 0 auto;
      width: var(--nav-cart--products-order-code);
    }
    &--success {
      height: var(--nav-cart--products-order-success-h);
      .icon {
        stroke-width: 2;
      }
      .tip {
        color: #575757;
      }
    }
  }
}

@include mobile {
  .nav-cart {
    padding: 0 0.24rem;
    .moonrise-button {
      width: 3.3rem;
      height: 0.37rem;
      line-height: 0.37rem;
    }

    &_products {
      &--foot {
        padding-bottom: 1.2rem;
      }
    }

    &_calendar {
      box-sizing: border-box;
      &--title {
        border-bottom: 1px solid var(--nav-cart-border-color);
      }
      &--body {
        height: 3.1rem;
      }
    }

    &_address {
      padding-bottom: 0.4rem;
      .input-group-detail {
        height: var(--nav-cart--address-oneline);
        .line1 {
          display: block;
          bottom: 0;
        }
        .placeholder {
          top: 0.18rem;
        }
        textarea {
          height: var(--nav-cart--address-oneline);
          line-height: calc(var(--nav-cart--address-oneline) + 0.1rem);
        }
      }
    }

    &_order {
      border-top: 1px solid var(--nav-cart-border-color);
    }
  }
}

@include pc {
  .nav-cart {
    border-radius: 0 0 var(--nav-dialog-radius) var(--nav-dialog-radius);
    width: var(--nav-dialog-width);
    height: calc(var(--vh) - 56px - var(--nav-dialog-header) - var(--nav-dialog-placeholder-height));
    overflow-y: auto;
    overflow-x: hidden;

    .moonrise-button {
      width: var(--nav-cart-btn-w);
    }

    &_products {
      background-color: var(--moonrise-dialog-bg);
      border-radius: 0 0 var(--nav-dialog-radius) var(--nav-dialog-radius);
      &--foot {
        padding-bottom: var(--nav-dialog-placeholder-height);
      }
    }

    &_calendar {
      border-radius: 0 0 var(--nav-dialog-radius) var(--nav-dialog-radius);
      background-color: var(--moonrise-dialog-bg);
      box-sizing: border-box;
      &--title {
        border-bottom: 1px solid var(--nav-cart-border-color);
      }
    }

    &_address {
      width: 100%;
      background-color: var(--moonrise-dialog-bg);
      padding: var(--nav-dialog-placeholder-height) var(--nav-cart--products-padding);
      border-radius: var(--nav-dialog-radius);
      .input-group-detail {
        height: calc(var(--nav-cart--address-oneline) * 2);
        .line1 {
          display: block;
          top: 50%;
        }
        .line2 {
          display: block;
          bottom: 0;
        }
        .placeholder {
          transform: translateY(100%);
        }
        textarea {
          height: calc(var(--nav-cart--address-oneline) * 2);
          line-height: 46px;
        }
        @include pc-large {
          textarea {
            line-height: 56px;
          }
        }
      }
    }

    &_order {
      height: var(--nav-cart--products-order-h);
      background-color: var(--moonrise-dialog-bg);
      border-radius: 10px 0 10px 10px;
      &--tip {
        border-top: 1px solid var(--nav-cart-border-color);
      }
      &--success {
        border-top: 1px solid var(--nav-cart-border-color);
      }
      &--btn {
        text-align: center;
        .moonrise-button {
          width: 327px;
        }
      }
    }
  }
}
