.cursor-pointer:hover,
.pointer:hover {
  cursor: pointer;
}

.cursor-not-allowed:hover,
.not-allowed:hover {
  cursor: not-allowed;
}

.cursor-move:hover {
  cursor: move;
}

.cursor-grab:hover {
  cursor: grab;
}

.cursor-arrow-left {
  cursor: w-resize;
}

.cursor-arrow-right {
  cursor: e-resize;
}
