@media (max-width: 1512px) {
  @for $i from 10 to 56 {
    @for $j from 10 to 56 {
      .fs-rem-#{$i}-#{$j} {
        font-size: #{$i}px;
      }
      .lh-rem-#{$i}-#{$j} {
        line-height: #{$i}px;
      }
    }
  }
}

@media (min-width: 1513px) {
  @for $i from 10 to 56 {
    @for $k from 10 to 56 {
      .fs-rem-#{$i}-#{$k} {
        font-size: #{$k}px;
      }
      .lh-rem-#{$i}-#{$k} {
        line-height: #{$k}px;
      }
    }
  }
}

@media (max-width: 833px) {
  @for $i from 8 to 50 {
    .fs-m-#{$i} {
      font-size: #{$i * 0.01}rem;
    }
    .lh-m-#{$i} {
      line-height: #{$i * 0.01}rem;
    }
  }
}

.fs-0 {
  font-size: 0px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.lh-0 {
  line-height: 0px;
}

.lh-normal {
  line-height: normal;
}
