$position: top, right, bottom, left;

@each $item in $position {
  .m#{str-slice($item, 0, 1)}-auto {
    margin-#{$item}: auto;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

@for $i from 2 to 100 {
  @each $item in $position {
    .m#{str-slice($item, 0, 1)}-#{$i} {
      margin-#{$item}: #{$i}px;
    }
    .p#{str-slice($item, 0, 1)}-#{$i} {
      padding-#{$item}: #{$i}px;
    }
  }
  .height-#{$i} {
    height: #{$i}px;
  }
  .width-#{$i} {
    width: #{$i}px;
  }
}

@media (min-width: 834px) and (max-width: 1512px) {
  @for $i from 1 to 50 {
    @for $j from 1 to 50 {
      @each $item in $position {
        .m#{str-slice($item, 0, 1)}-rem-#{$i}-#{$j} {
          margin-#{$item}: #{$i}px;
        }
        .p#{str-slice($item, 0, 1)}-rem-#{$i}-#{$j} {
          padding-#{$item}: #{$i}px;
        }
      }
      .h-rem-#{$i}-#{$j} {
        height: #{$i}px;
      }
      .w-rem-#{$i}-#{$j} {
        width: #{$i}px;
      }
    }
  }
}

@media (min-width: 1513px) {
  @for $i from 1 to 50 {
    @for $k from 1 to 50 {
      @each $item in $position {
        .m#{str-slice($item, 0, 1)}-rem-#{$i}-#{$k} {
          margin-#{$item}: #{$k}px;
        }
        .p#{str-slice($item, 0, 1)}-rem-#{$i}-#{$k} {
          padding-#{$item}: #{$k}px;
        }
      }
      .h-rem-#{$i}-#{$k} {
        height: #{$k}px;
      }
      .w-rem-#{$i}-#{$k} {
        width: #{$k}px;
      }
    }
  }
}

@media (max-width: 833px) {
  @for $i from 2 to 50 {
    @each $item in $position {
      .m#{str-slice($item, 0, 1)}-m-#{$i} {
        margin-#{$item}: #{$i * 0.01}rem;
      }
      .p#{str-slice($item, 0, 1)}-m-#{$i} {
        padding-#{$item}: #{$i * 0.01}rem;
      }
    }
    .height-m-#{$i} {
      height: #{$i * 0.01}rem;
    }
    .width-m-#{$i} {
      width: #{$i * 0.01}rem;
    }
  }
}

.mt-m-0 {
  margin-top: 0px;
}

.pt-m-0 {
  padding-top: 0px;
}