@mixin flex($justify: flex-start, $align: flex-start) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  @content;
}

@mixin ipx-safe-bottom {
  box-sizing: content-box;
  /* 兼容 iOS < 11.2 */
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
}

@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-top-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin placeholder-selector($ele: 'input') {
  #{$ele}::-webkit-input-placeholder {
    @content;
  }
  #{$ele}:-moz-placeholder {
    @content;
  }
  #{$ele}::-moz-placeholder {
    @content;
  }
  #{$ele}:-ms-input-placeholder {
    @content;
  }
}