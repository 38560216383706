@import 'styles/var.scss';
@import 'styles/layout.scss';
@import 'styles/flex.scss';
@import 'styles/font.scss';
@import 'styles/arco.scss';
@import 'styles/cursor.scss';
@import 'styles/responsive.scss';

@font-face {
  font-family: 'hiragino-sans';
  src: url('https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/font/hiragino-sans-w3.otf');
}

@font-face {
  font-family: 'optician-sans';
  src: url('https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/font/optician-sans.ttf');
}

html,
body {
  height: 100%;
  line-height: normal;
}

body {
  background-color: var(--moonrise-bg);
}

#root {
  height: 100%;
}

button {
  outline: none;
  box-shadow: none;
  border: none;
}
button:hover {
  cursor: pointer;
}

input,
textarea {
  background-color: rgba($color: #000000, $alpha: 0);
  border: none;
  box-shadow: none;
  resize: none;
}

.optician-sans,
.op-sans {
  font-family: 'optician-sans' !important;
}

.hiragino-sans,
.hi-sans {
  font-family: 'hiragino-sans' !important;
}

@include mobile {
  .mobile-hidden {
    display: none;
  }
}

@include pc {
  .pc-hidden {
    display: none;
  }
}

.element-hidden {
  opacity: 0;
  pointer-events: none;
}

.element-visible {
  opacity: 1;
}

.pointer-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none;
}

@for $i from 0 to 10 {
  .opacity-#{$i} {
    opacity: #{$i * 0.1};
  }
}

.text-center {
  text-align: center;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.color-fff {
  color: #fff;
}

.color-000 {
  color: #000000;
}

.relative {
  position: relative;
}

.moonrise-button {
  height: 40px;
  line-height: 38px;
  text-align: center;
  width: 187px;
  margin: 0 auto;
  background-color: var(--moonrise-color-green) !important;
  color: var(--moonrise-bg) !important;
  border-radius: 20px;
  span {
    color: var(--moonrise-bg) !important;
  }
  .arco-icon-loading {
    stroke: var(--moonrise-bg);
  }
}

.moonrise-button-border {
  background-color: rgba($color: #000000, $alpha: 0) !important;
  box-sizing: border-box !important;
  border: 1px solid #fff !important;
  span {
    color: #fff !important;
  }
  .arco-icon-loading {
    stroke: #fff !important;
  }
}

.moonrise-button::before {
  opacity: 0;
}

@include mobile {
  .page-logo {
    height: 0.9rem;
    background-image: url('https://moonrise.oss-cn-shenzhen.aliyuncs.com/web/assets/logo.png');
    background-repeat: no-repeat;
    background-size: var(--nav--logo-w) auto;
    background-position: center 0rem;
    margin-top: var(--nav-height);
  }
}

@include pc-large {
  .moonrise-button {
    height: 48px;
    line-height: 48px;
    border-radius: 28px;
    .arco-icon-loading {
      font-size: 20px;
    }
  }
}
