@import '../../styles/mixin.scss';
@import '../../styles/responsive.scss';

.counter {
  display: flex;
  %btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .line-h,
    .line-v {
      @include absolute-center;
    }
    .line-v {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  &_btn {
    @extend %btn;
    &:hover {
      cursor: pointer;
    }
    border: 1px solid var(--moonrise-color-green);
    background-color: var(--moonrise-color-green);
    .line-h,
    .line-v {
      background-color: var(--moonrise-bg);
    }
  }
  &_btn-small {
    width: 40px;
    height: 18px;
    border-radius: 16px;
    .line-h,
    .line-v {
      height: 2px;
      width: 9px;
    }
  }
  &_btn-medium {
    width: 46px;
    height: 21px;
    border-radius: 21px;
    .line-h,
    .line-v {
      height: 2px;
      width: 12px;
    }
  }
  &_btn-disabled {
    border: 1px solid #fff;
    background-color: transparent;
    .line-h,
    .line-v {
      background-color: #fff;
    }
  }

  &_val-small {
    text-align: center;
    width: 32px;
    font-size: 16px;
    line-height: 16px;
  }
  &_val-medium {
    text-align: center;
    width: 38px;
    font-size: 22px;
    line-height: 20px;
  }
}

@include pc-large {
  .counter {
    &_btn-small {
      width: 53px;
      height: 26px;
      border-radius: 26px;
      .line-h,
      .line-v {
        height: 2px;
        width: 12px;
      }
    }

    &_btn-medium {
      width: 67px;
      height: 29px;
      border-radius: 29px;
      .line-h,
      .line-v {
        height: 3px;
        width: 16px;
      }
    }
  
    &_val-small {
      width: 43px;
      font-size: 26px;
      line-height: 26px;
    }
    &_val-medium {
      width: 44px;
      font-size: 29px;
      line-height: 29px;
    }
  }
}

