@import '../../../../styles/responsive.scss';

%check {
  display: inline-block;
  height: 10px;
  width: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 0px;
}

.check-icon {
  @extend %check;
}

.checked-icon {
  @extend %check;
  background-color: #fff;
}

@include mobile {
  .check-icon, .checked-icon {
    height: 0.08rem;
    width: 0.08rem;
  }
}

@include pc-large {
  .check-icon, .checked-icon {
    height: 12px;
    width: 12px;
  }
}
