@import '../../styles/mixin.scss';

.scale-font {
  position: relative;
  overflow: hidden;
  &--text {
    @include absolute-center;
    transform: translate(-50%, -50%) scale(0.5);
    white-space: nowrap;
  }
}
