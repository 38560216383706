@import '../../styles/mixin.scss';
@import '../../styles/responsive.scss';

.nav {
  @include flex(center, flex-end);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  width: 100%;
  height: var(--nav-height);
  box-sizing: border-box;
  padding: 0 70px;

  &_hamburger,
  &_info,
  &_cart {
    display: none;
    position: absolute;
    top: var(--nav--infocart-top);
  }

  &_info {
    display: block;
    left: var(--nav--infocart-left);
  }
  &_cart {
    display: block;
    right: var(--nav--infocart-left);
  }

  &_logo {
    @include flex(center, center);
    flex-shrink: 0;
    line-height: 0;
  }
}

.nav-dialog {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: var(--vh);
  transform-origin: 0 0;
  &_info {
    transform-origin: 0 0;
  }
  &_cart {
    transform-origin: right 0;
  }
}

@include mobile {
  .nav {
    transition: all 0.3s;
    &_link {
      display: none;
    }
    &_logo {
      display: none;
      &--img {
        width: var(--nav--logo-w);
        height: auto;
      }
    }

    &_hamburger {
      display: block;
      top: 0.3rem;
      & > div {
        height: 0.03rem;
        width: 0.25rem;
        background-color: #fff;
        &:nth-child(2) {
          margin-top: 0.06rem;
        }
      }
    }
  }

  .nav-active {
    background-color: var(--moonrise-bg);
  }

  .nav-dialog {
    z-index: 39;
    &_info,
    &_cart,
    &_mobile {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background-color: var(--moonrise-bg);
      overflow-y: auto;
      overflow-x: hidden;
      &--header {
        height: var(--nav-dialog-header);
        position: relative;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-left: 0.24rem;
      }
      &--placeholder {
        height: 0px;
      }
      &--body {
        width: 100%;
      }
    }

    &_mobile {
      &--body {
        padding-top: 1.2rem;
        box-sizing: border-box;
        .link {
          width: 2.65rem;
          height: 0.61rem;
          border-radius: 0.3rem;
          box-sizing: border-box;
          border: 0.02rem solid #fff;
          margin-bottom: 0.2rem;
        }
        .link-active {
          background-color: #fff;
          color: var(--moonrise-bg);
        }
      }
    }
  }
}

@include pc {
  .nav {
    &_logo {
      width: calc(var(--nav--logo-w) + 80px);
      &--img {
        width: var(--nav--logo-w);
        height: auto;
      }
    }

    &_link {
      width: 100%;
      flex-grow: 1;
      &--btn {
        width: var(--nav--btn-w);
        height: var(--nav--btn-h);
        line-height: calc(var(--nav--btn-h) - 4px);
        border-radius: calc(var(--nav--btn-h) / 2);
        border: 2px solid #fff;
        margin-top: auto;
        transition: all 0.3s;
        text-align: center;
        padding: 0 var(--nav--btn-padding);
      }
      &--btn-hover:hover {
        background-color: #fff;
        color: var(--moonrise-bg);
      }
      &--btn-works {
        width: 100%;
        text-align: left;
      }
      &--btn-product {
        width: 100%;
        text-align: right;
      }
    }
  }

  .nav-dialog {
    &_info,
    &_cart {
      position: absolute;
      top: var(--nav-dialog-top);
      z-index: 39;
      &--header {
        background-color: var(--moonrise-dialog-bg);
        height: var(--nav-dialog-header);
        border-radius: var(--nav-dialog-radius) var(--nav-dialog-radius) 0 0;
        width: var(--nav-dialog-header-width);
        position: relative;
        .close {
          position: absolute;
          bottom: 4px;
        }
      }
      &--placeholder {
        height: var(--nav-dialog-placeholder-height);
        background-color: var(--moonrise-dialog-bg);
      }
      $bodyHeight: calc(var(--vh) - 56px - var(--nav-dialog-header) - var(--nav-dialog-placeholder-height));
      $bodyWidth: var(--nav-dialog-width);
      &--body {
        width: $bodyWidth;
        height: $bodyHeight;
        overflow-y: auto;
      }
      &--pad {
        position: relative;
        width: $bodyWidth;
        height: $bodyHeight;
        .pad-placeholder {
          @include px2vh(height, 280);
          background-color: var(--moonrise-dialog-bg);
        }
        .pad-body {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    &_info {
      left: var(--nav-dialog-postion);
      &--header {
        .close {
          left: var(--nav-close-postion);
        }
      }
      &--placeholder {
        border-radius: 0 var(--nav-dialog-radius) 0 0;
      }
      &--body {
        border-radius: 0 0 var(--nav-dialog-radius) var(--nav-dialog-radius);
      }
      &--pad {
        .pad-body {
          width: var(--nav-dialog-width);
          height: calc(var(--vh) - 56px - var(--nav-dialog-header) - var(--nav-dialog-placeholder-height));
          overflow-y: auto;
        }
      }
    }
    &_cart {
      right: var(--nav-dialog-postion);
      &--header {
        margin-left: auto;
        .close {
          right: var(--nav-close-postion);
        }
      }
      &--placeholder {
        border-radius: var(--nav-dialog-radius) 0 0 0;
      }
      &--body {
        border-radius: 0 0 var(--nav-dialog-radius) var(--nav-dialog-radius);
      }
    }
  }
}

@keyframes nav-dialog-in {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }

  95% {
    opacity: 1;
    transform: scale(1.005);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes nav-dialog-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.nav-dialog-transition-enter {
  .nav-dialog_info,
  .nav-dialog_cart {
    opacity: 0;
    transform: scale(0.95);
  }
}
.nav-dialog-transition-enter-active {
  .nav-dialog_info,
  .nav-dialog_cart {
    animation: nav-dialog-in 0.3s forwards;
  }
}
.nav-dialog-transition-exit {
  .nav-dialog_info,
  .nav-dialog_cart {
    animation: nav-dialog-out 0.3s forwards;
  }
}
