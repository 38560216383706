@import '../../../styles/responsive.scss';

@include mobile {
  .nav-info {
    padding-bottom: 1.2rem;
    &_desc {
      padding: 0px 0.3rem 0px 0.24rem;
      .logo2 {
        width: 0.44rem;
        height: auto;
        position: absolute;
        right: 10px;
        top: 3px;
      }
    }
    &_service {
      padding-left: 0.24rem;
      &--images {
        margin-left: -4px;
        .phone {
          width: 1.93rem;
          height: auto;
        }
        .code {
          text-align: center;
          img {
            width: 1.06rem;
            height: auto;
          }
        }
      }
      &--cpright {
        transform: scale(0.75);
        margin-top: 0.15rem;
        margin-left: -0.54rem;
      }
    }
  }
}

@include pc {
  .nav-info {
    border-radius: 10px 0px var(--nav-dialog-radius) var(--nav-dialog-radius);
    &_desc {
      border-radius: 0px 0px var(--nav-dialog-radius) var(--nav-dialog-radius);
      padding: 0px 26px 35px 26px;
      background-color: var(--moonrise-dialog-bg);
      .logo2 {
        width: 58px;
        height: auto;
        position: absolute;
        right: 10px;
        top: 3px;
      }
    }
    &_service {
      border-radius: var(--nav-dialog-radius);
      height: 285px;
      box-sizing: border-box;
      background-color: var(--moonrise-dialog-bg);
      padding-left: 31px;
      &--images {
        .phone {
          width: 230px;
          height: auto;
          margin-top: 16px;
        }
        .code {
          text-align: center;
          img {
            width: 128px;
            height: auto;
          }
        }
      }
    }
  }

  @include pc-large {
    .nav-info {
      &_desc {
        padding: 0px 35px 53px 41px;
        .logo2 {
          width: 88px;
        }
      }
      &_service {
        height: 384px;
        margin-top: 22px;
        padding-left: 43px;
        &--images {
          .phone {
            width: 324px;
            margin-left: 33px;
            margin-top: 20px;
          }
          .code {
            margin-top: 76px;
            img {
              width: 172px;
            }
          }
        }
      }
    }
  }
}
