@mixin mobile() {
  @media (max-width: 833px) {
    // <= 833
    @content;
  }
}

@mixin pc() {
  @media (min-width: 834px) {
    @content;
  }
}

@mixin pc-small() {
  // apple电脑端最小834
  @media (min-width: 834px) and (max-width: 1512px) {
    @content;
  }
}

@mixin pc-large() {
  @media (min-width: 1513px) {
    @content;
  }
}

// 默认设计稿的宽度
$designWidth: 1512;
// 默认设计稿的高度
$designHeight: 982;

@mixin px2vw($name, $px) {
  #{$name}: calc($px / $designWidth) * 100vw;
}

@mixin px2vh($name, $px) {
  // #{$name}: calc($px / $designHeight) * 100vh;
  #{$name}: calc(($px / $designHeight) * var(--vh))
}

@mixin vwwidth($px) {
  @include px2vw(width, $px);
  min-width: $px * 1px;
}

@mixin vhheight($px) {
  @include px2vh(height, $px);
  min-height: $px * 1px;
}

@mixin remBackgroundPositionY($x, $px) {
  background-position: $x calc($px / $designHeight) * var(--vh);
}

@mixin remDiyNav($px) {
  transform: translate(-50%, calc(-100% - calc($px / $designHeight) * var(--vh)));
}