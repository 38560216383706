.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-align-baseline {
  display: flex;
  align-items: baseline;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.flex-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-around {
  display: flex;
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-justify-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.flex-column-align-end {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 0;
}