@import '../../styles/mixin.scss';

.tiny-font {
  position: relative;
  overflow: hidden;
  display: block;
  &-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
  &--text {
    @include absolute-center;
    transform: translate(-50%, -50%) scale(0.5);
    white-space: nowrap;
  }
}
