@import './responsive.scss';

body {
  --arcoblue-1: 207, 223, 7;
  --arcoblue-2: 207, 223, 7;
  --arcoblue-3: 207, 223, 7;
  --arcoblue-4: 207, 223, 7;
  /* 悬浮 */
  --arcoblue-5: 207, 223, 7;
  --arcoblue-6: 207, 223, 7;
  /* 点击 */
  --arcoblue-7: 207, 223, 7;
  /* moonrise */
  --moonrise-bg: rgba(14, 14, 14, 1); // #0e0e0e
  --moonrise-dialog-bg: #151515;
  --moonrise-dialog-mask-bg: rgba(0, 0, 0, 1);
  --moonrise-mask-bg: rgba(0, 0, 0, 0.5);
  --moonrise-color-gray: #424242;
  --moonrise-color-green: #cfdf07;
  --moonrise-color-line: rgba(225, 225, 225, 1); // #e1e1e1

  --moonrise-color-gray-checkbox: rgba(244, 244, 244, 1); // #f4f4f4
  --moonrise-color-gray-placeholder: rgba(189, 189, 189, 1); // #BDBDBD

  --moonrise-color-diy-line: rgba(223, 223, 223, 1); // #DFDFDF
}

@include mobile {
  body {
    /* global */
    --nav-height: 0.67rem;
    --nav--logo-w: 274px;

    --nav--infocart-top: 0.25rem;
    --nav--infocart-left: 0.17rem;

    --nav-dialog-header: 1.05rem;

    /* nav cart */
    --nav-cart--products-padding: 0px;
    --nav-cart--products-product-height: 0.78rem;
    --nav-cart--products-product-remove-w: 0.43rem;
    --nav-cart--products-product-remove-h: 0.12rem;
    --nav-cart--products-empty-h: 0.72rem;

    --nav-cart--address-oneline: 0.42rem;
    --nav-cart--address-input-group: 0.24rem;
    --nav-cart--address-input-group-lh: 0.25rem;
    --nav-cart--address-input-ml: 0.27rem;
    --nav-cart--address-label-ml: 0.12rem;
    --nav-cart--address-name-w: 1.29rem;
    --nav-cart--address-tel-w: 1.92rem;

    --nav-cart--products-order-h: auto;
    --nav-cart--products-order-code: 1.7rem;
    --nav-cart--products-order-success-h: 3rem;

    --nav-cart--calendar-width-plus: 0.3rem;
    --nav-cart--calendar-row-height: 0.36rem;
    --nav-cart--calendar-head-height: 0.3rem;
    --nav-cart--calendar-placeholder-height: 2.3rem;
    --nav-cart--calendar-line-width: 0.24rem;
    --nav-cart--calendar-line-height: 2px;
    --nav-cart--calendar-line-bottom: 0.02rem;
    --nav-cart--calendar-switch-mr: 0.46rem;

    --diy-input-placeholder-size: 0.15rem;
    --diy-input-line-height: 0.3rem;

    --product--detail-spec: 0.24rem;

    --works-item--price-height: 0.41rem;
    --works-item--price-width: 0.34rem;
    --works-item--price-bottom: 0.17rem;
    --works-item--counter-mt: 0.16rem;

    --diy-close-size: 0.36rem;
    --diy-close-top: 0.15rem;
    --diy-foot-size: 1.45rem;
    --diy-foot--mask: 0.31rem;
    --diy-foot--btn-height: 0.45rem;
    --diy-foot--btn-width: 1.71rem;
    --diy-foot--total-height: 0.56rem;

    --diy-view--selector-height: 0.5rem;
    --diy-view--selector-bottom: 0.2rem;
    --diy-view--selector--scroller-w: 3.34rem;
    --diy-view--selector--scroller-h: 0.3rem;
    --diy-view--selector--scroller-item-w: 0.44rem;
    --diy-view--selector--scroller-item-num: 0.15rem;
    --diy-view--selector--scroller-item-line: 0.1rem;
    --diy-view--selector--scroller-item-line-div: 0.07rem;

    --diy-menu--title-mr: 0.15rem;

    --diy-menu--checkbox-container-padding: 0.11rem;
    --diy-menu--checkbox-height: 0.69rem;
    --diy-menu--checkbox-radius: 0.1rem;
    --diy-menu--checkbox-ml: 0.05rem;
    --diy-menu--checkbox-color-size: 0.23rem;
    --diy-menu--checkbox-color-mb: 0.05rem;
    --diy-menu--checkbox-step1-count-height: 0.1rem;
    --diy-menu--checkbox-step1-spec-height: 0.06rem;

    --diy-menu--step1-package-radius: 0.05rem;
    --diy-menu--step1-package-color-size: 0.15rem;
    --diy-menu--step1-package-color-ml: -0.07rem;
    --diy-menu--step1-package-cover-width: 0.51rem;
    --diy-menu--step1-package-cover-height: 0.53rem;
    --diy-menu--step1-package-cover-radius: 0.05rem;
    --diy-menu--step2-flower-left: 0.2rem;
    --diy-menu--step2-flower-bottom: 0.2rem;
    --diy-menu--step2-flower-color-height: 0.27rem;
    --diy-menu--step4--info-height: 0.7rem;
    --diy-menu--step4--info-text-width: 5.06rem;
    --diy-menu--step4--info-text-height: 0.44rem;
    --diy-menu--step4--summary-flower-height: 0.77rem;
    --diy-menu--step4--summary-flower-cover-size: 0.59rem;
    --diy-menu--step4--summary-flower-cover-radius: 0.04rem;
    --diy-menu--step4--summary-flower-color-size: 0.1rem;
  }
}

@include pc-small {
  body {
    /* global */
    --nav-height: 108px;

    /* nav */
    --nav--infocart-top: 33px;
    --nav--infocart-left: 40px;
    --nav--logo-w: 260px;
    --nav--btn-w: 182px;
    --nav--btn-h: 40px;
    --nav--btn-padding: 28px;
    --nav-close-postion: 26px;
    --nav-close-bottom: 2px;

    /* nav 弹窗 */
    --nav-dialog-top: 22px;
    --nav-dialog-postion: 33px;
    --nav-dialog-header: 42px;
    --nav-dialog-header-width: 111px;
    --nav-dialog-width: 443px;
    --nav-dialog-placeholder-height: 36px;
    --nav-dialog-radius: 10px;

    /* nav cart */
    --nav-cart-btn-w: 365px;

    --nav-cart--products-padding: 26px;
    --nav-cart--products-empty-h: 72px;
    --nav-cart--products-product-height: 84px;
    --nav-cart--products-product-remove-w: 46px;
    --nav-cart--products-product-remove-h: 13px;

    --nav-cart--products-order-h: 417px;
    --nav-cart--products-order-code: 200px;
    --nav-cart--products-order-success-h: 313px;

    --nav-cart--calendar-width-plus: 38px;
    --nav-cart--calendar-row-height: 46px;
    --nav-cart--calendar-head-height: 40px;
    --nav-cart--calendar-placeholder-height: 230px;
    --nav-cart--calendar-line-width: 28px;
    --nav-cart--calendar-line-height: 2px;
    --nav-cart--calendar-line-bottom: 5px;
    --nav-cart--calendar-switch-mr: 54px;

    --nav-cart--address-oneline: 46px;
    --nav-cart--address-input-group: 30px;
    --nav-cart--address-input-group-lh: 34px;
    --nav-cart--address-input-ml: 25px;
    --nav-cart--address-label-ml: 12px;
    --nav-cart--address-name-w: 153px;
    --nav-cart--address-tel-w: 226px;

    /* category */
    --category-height: 43px;
    --category-radius: 26px;
    --category-more--leftright: 54px;

    --product--detail-spec: 24px;

    --works-item--price-height: 32px;
    --works-item--price-width: 34px;
    --works-item--price-bottom: 17px;
    --works-item--counter-mt: calc(0.028513238289205704 * var(--vh));

    --diy-close-size: 61px;
    --diy-close-top: 39px;
    --diy-foot-size: 135px;
    --diy-foot--mask: 47px;
    --diy-foot--btn-height: 48px;
    --diy-foot--btn-width: 204px;
    --diy-foot--total-height: 50px;
    --diy-input-placeholder-size: 17px;
    --diy-input-line-height: 39px;
    --diy-tip--text-width: 158px;
    --diy-tip--code-width: 197px;
    --diy-tip--close-size: 23px;

    --diy-view--selector-height: 60px;
    --diy-view--selector-bottom: 36px;
    --diy-view--selector--scroller-w: 380px;
    --diy-view--selector--scroller-h: 34px;
    --diy-view--selector--scroller-item-w: 36px;
    --diy-view--selector--scroller-item-num: 17px;
    --diy-view--selector--scroller-item-line: 12px;
    --diy-view--selector--scroller-item-line-div: 7px;

    --diy-menu--title-mr: 15px;

    --diy-menu--checkbox-container-padding: 16px;
    --diy-menu--checkbox-height: 73px;
    --diy-menu--checkbox-radius: 5px;
    --diy-menu--checkbox-ml: 10px;
    --diy-menu--checkbox-color-size: 35px;
    --diy-menu--checkbox-color-mb: 4px;
    --diy-menu--checkbox-step1-count-height: 11px;
    --diy-menu--checkbox-step1-spec-height: 8px;

    --diy-menu--step1-package-radius: 5px;
    --diy-menu--step1-package-color-size: 24px;
    --diy-menu--step1-package-color-ml: -10px;
    --diy-menu--step1-package-cover-width: 101px;
    --diy-menu--step1-package-cover-height: 105px;
    --diy-menu--step1-package-cover-radius: 4px;
    --diy-menu--step2-flower-left: 20px;
    --diy-menu--step2-flower-bottom: 24px;
    --diy-menu--step2-flower-color-height: 31px;
    --diy-menu--step4--info-height: 87px;
    --diy-menu--step4--info-text-width: 560px;
    --diy-menu--step4--info-text-height: 48px;
    --diy-menu--step4--summary-flower-height: 82px;
    --diy-menu--step4--summary-flower-cover-size: 65px;
    --diy-menu--step4--summary-flower-cover-radius: 4px;
    --diy-menu--step4--summary-flower-color-size: 12px;
  }
}

@include pc-large {
  body {
    /* global */
    --nav-height: 160px;

    --nav--infocart-top: 50px;
    --nav--infocart-left: 60px;
    --nav--logo-w: 398px;
    --nav--btn-w: 278px;
    --nav--btn-h: 60px;
    --nav--btn-padding: 60px;
    --nav-close-postion: 41px;

    --nav-dialog-top: 36px;
    --nav-dialog-postion: 48px;
    --nav-dialog-header: 58px;
    --nav-dialog-header-width: 149px;
    --nav-dialog-width: 596px;
    --nav-dialog-placeholder-height: 48px;
    --nav-dialog-radius: 16px;

    --nav-cart-btn-w: 438px;

    --nav-cart--products-padding: 35px;
    --nav-cart--products-empty-h: 105px;
    --nav-cart--products-product-height: 120px;
    --nav-cart--products-product-remove-w: 61px;
    --nav-cart--products-product-remove-h: 17px;

    --nav-cart--products-order-h: 580px;
    --nav-cart--products-order-code: 270px;
    --nav-cart--products-order-success-h: 423px;

    --nav-cart--calendar-width-plus: 40px;
    --nav-cart--calendar-row-height: 62px;
    --nav-cart--calendar-head-height: 60px;
    --nav-cart--calendar-placeholder-height: 300px;
    --nav-cart--calendar-line-width: 38px;
    --nav-cart--calendar-line-height: 3px;
    --nav-cart--calendar-line-bottom: 6px;
    --nav-cart--calendar-switch-mr: 76px;

    --nav-cart--address-oneline: 54px;
    --nav-cart--address-input-group: 36px;
    --nav-cart--address-input-group-lh: 42px;
    --nav-cart--address-input-ml: 44px;
    --nav-cart--address-label-ml: 19px;
    --nav-cart--address-name-w: 205px;
    --nav-cart--address-tel-w: 305px;

    --category-height: 57px;
    --category-radius: 32px;
    --category-more--leftright: 74px;

    --product--detail-spec: 32px;

    --works-item--price-height: 57px;
    --works-item--price-width: 60px;
    --works-item--price-bottom: 30px;
    --works-item--counter-mt: 37px;

    --diy-close-size: 98px;
    --diy-close-top: 39px;
    --diy-input-placeholder-size: 20px;
    --diy-input-line-height: 49px;
    --diy-foot-size: 197px;
    --diy-foot--mask: 81px;
    --diy-foot--btn-height: 67px;
    --diy-foot--btn-width: 291px;
    --diy-foot--total-height: 72px;
    --diy-tip--text-width: 208px;
    --diy-tip--code-width:  315px;
    --diy-tip--close-size: 36px;

    --diy-view--selector-height: 85px;
    --diy-view--selector-bottom: 64px;
    --diy-view--selector--scroller-w: 596px;
    --diy-view--selector--scroller-h: 53px;
    --diy-view--selector--scroller-item-w: 48px;
    --diy-view--selector--scroller-item-num: 26px;
    --diy-view--selector--scroller-item-line: 18px;
    --diy-view--selector--scroller-item-line-div: 10px;

    --diy-menu--title-mr: 20px;

    --diy-menu--checkbox-container-padding: 26px;
    --diy-menu--checkbox-height: 117px;
    --diy-menu--checkbox-radius: 10px;
    --diy-menu--checkbox-ml: 16px;
    --diy-menu--checkbox-color-size: 45px;
    --diy-menu--checkbox-color-mb: 11px;
    --diy-menu--checkbox-step1-count-height: 16px;
    --diy-menu--checkbox-step1-spec-height: 11px;

    --diy-menu--step1-package-radius: 8px;
    --diy-menu--step1-package-color-size: 33px;
    --diy-menu--step1-package-color-ml: -14px;
    --diy-menu--step1-package-cover-width: 143px;
    --diy-menu--step1-package-cover-height: 149px;
    --diy-menu--step1-package-cover-radius: 8px;
    --diy-menu--step2-flower-left: 40px;
    --diy-menu--step2-flower-bottom: 36px;
    --diy-menu--step2-flower-color-height: 52px;
    --diy-menu--step4--info-height: 140px;
    --diy-menu--step4--info-text-width: 900px;
    --diy-menu--step4--info-text-height: 76px;
    --diy-menu--step4--summary-flower-height: 132px;
    --diy-menu--step4--summary-flower-cover-size: 105px;
    --diy-menu--step4--summary-flower-cover-radius: 10px;
    --diy-menu--step4--summary-flower-color-size: 18px;
  }
}
